import '@geoapify/geocoder-autocomplete/styles/minimal.css'
import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete'
import { useEffect, useState } from 'react'
import {getAutocomplete} from '../utils.js'

import { Loader } from './loader.js';



export const Form = ({ onSubmitForm, selectedAcctTitle, selectedAccount, toggleFormFormat }) => {

    let today = new Date()
    today = today.toISOString().split('T')[0]

    // const [countryCodes, setcountryCodes] = useState(["US", "CA"])

    const [isSameDay, setIsSameDay] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [formData, setFormData] = useState(
    {eqType: "VAN",shipDate: today,pickupCity: "",pickupState: "",pickupCountry: "US",pickupZip: "",dropoffCity: "",dropoffState: "",dropoffCountry: "US",dropoffZip: ""});

    const [isPickupAutoCoord, setIsPickupAutoCoord] = useState(false);
    const [isDropoffAutoCoord, setIsDropoffAutoCoord] = useState(false);
    
    const [coords, setCoords] = useState({})
    const [stops, setStops] = useState([])

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          pickupDateTime: formData.shipDate.concat("T00:00:00Z"),
          transportType: formData.eqType == "HOTSHOT" ? "FLATBED" : formData.eqType,
          stops: stops,
          commodity: 'Racks',
          tag: 'Pro#1234',
          currency: 'USD'
        })
      };

      const handleStopsArrUpdate = (stopsUpdate) => {
        console.log("are we updating the top level stops array")
        const nextStops = stopsUpdate.map(stop => {   
                if(stop.zip == null){
                    return{
                        order: stop.id,
                        city: stop.city,
                        state: stop.state,
                        country: stop.country,
                        zip: ''
                    }
                }  else {
                    return {
                        order: stop.id,
                        city: stop.city,
                        state: stop.state,
                        country: stop.country,
                        zip: stop.zip
                    }
                }
        })
        setStops(nextStops)
        setFormData((prevFormData) => ({...prevFormData, pickupCity: stopsUpdate[0].city, pickupState: stopsUpdate[0].state, dropoffCity: stopsUpdate[stopsUpdate.length-1].city, dropoffState: stopsUpdate[stopsUpdate.length-1].state}))
        setCoords({pLat: stopsUpdate[0].lat, pLon: stopsUpdate[0].lon, dLon: stopsUpdate[stopsUpdate.length-1].lon, dLat: stopsUpdate[stopsUpdate.length-1].lat})
        console.log("i know this is dumb", stops)
        console.log("i know this is coords dumb", coords)

      }

    const handleCheck = () => {
        setIsSameDay(!isSameDay)
        console.log(isSameDay)
      };
    
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSearching(true)

        console.log("what are the form fields before submitting", formData)

        console.log(
            "flat mult", selectedAccount.eqMarkup.flat.mult,
            "flat add", selectedAccount.eqMarkup.flat.add
        )
    
          // Get Lane Rate API data
          fetch("https://lane-ojolp2uv4q-uc.a.run.app", requestOptions).then(
            response => response.json()
          ).then(
            lane => { 
              console.log('lane data is', lane)
              // Get Network Rate API data
              fetch("https://network-ojolp2uv4q-uc.a.run.app", requestOptions).then(
                response => response.json()
              ).then(
                network => {
    
                  //Calculate Confidence interval, Margin, check for Same Day shipment (This fn is in utils.js file)
                //   let rate = calcMargin(lane, network, formData, isSameDay)
                    console.log("lane", lane, "network", network)

                  let confCalc = getConfidenceCalc(lane, network)

                  let pickedRate = confCalc.rate;                
                  let lowConfidenceFlag = confCalc.conFlag;
              
                  let rateCalc = calcRate(pickedRate)

                  let rate = rateCalc.rate
                  let eqMarkup = rateCalc.eqMarkup
   
                onSubmitForm(rate, formData, pickedRate, eqMarkup, lowConfidenceFlag, isSameDay, coords, isPickupAutoCoord, isDropoffAutoCoord)
                setIsSearching(false)

                setIsPickupAutoCoord(false)
                setIsDropoffAutoCoord(false)
                
                }
              )        
            }
          )
             
      }

    useEffect(() => {
        if(toggleFormFormat === "Zip"){
            setFormData((prev) => ({...prev, pickupCity: "Erie", pickupState: "PA", dropoffCity: "Pittsburgh", dropoffState: "PA"}))
        } else {
            setFormData((prev) => ({...prev, pickupCity: "", pickupState: "", dropoffCity: "", dropoffState: "", pickupZip: "", dropoffZip: ""}))

        }
    }, [toggleFormFormat])

    useEffect(() => {
        if(selectedAcctTitle == "PTPRSAND"){
            setFormData((prev) => ({...prev, pickupCity: "Sandusky", pickupState: "OH"}))
        } else {
            setFormData((prev) => ({...prev, pickupCity: "", pickupState: ""}))
        }

    }, [selectedAcctTitle])

    const getConfidenceCalc = (lane, network) => {
        // Confidence Interval Logic
        let pickedRate, lowConfidenceFlag

        if(lane.confidenceLevel <= 70 && network.confidenceLevel >= 70){
            pickedRate = network;
            console.log("using network rate");
          } else if (lane.confidenceLevel <= 70 && network.confidenceLevel <= 70) {
              if(lane.targetBuyRate > network.targetBuyRate){
                pickedRate = lane;
                lowConfidenceFlag = true;
                  console.log("using lane rate");
      
              } else {
                pickedRate = network;
                lowConfidenceFlag = true;
                  console.log("using network rate");
              }
          } else {
            pickedRate = lane;
              console.log("using lane rate");
          }

          return {rate: pickedRate, conFlag: lowConfidenceFlag}
    }

    const calcRate = (pickedRate) => {
        let eqMarkup, marginRate

        switch(formData.eqType) {
            case "VAN":
                eqMarkup = selectedAccount.eqMarkup.van.mult
                marginRate = pickedRate.targetBuyRate * pickedRate.distance * eqMarkup
                console.log("VAN margin made rate " + marginRate)
                break
            case "FLATBED":
                if(selectedAcctTitle == "ICL") { 
                    eqMarkup = 1.11
                    marginRate = pickedRate.targetBuyRate * pickedRate.distance * eqMarkup + 150
                } else {
                    eqMarkup = selectedAccount.eqMarkup.flat.mult
                    marginRate = pickedRate.targetBuyRate * pickedRate.distance * eqMarkup
                }                       
                console.log("FLATBED margin made rate " + marginRate)
                break
            case "REEFER":
                eqMarkup = selectedAccount.eqMarkup.reefer.mult
                marginRate = pickedRate.targetBuyRate * pickedRate.distance * eqMarkup
                console.log("REEFER margin made rate " + marginRate)
                break
            case "HOTSHOT":
                eqMarkup = selectedAccount.eqMarkup.hotshot.mult
                marginRate = pickedRate.targetBuyRate * pickedRate.distance * eqMarkup;
                console.log("HOTSHOT margin made rate " + marginRate)
          }
        
          isSameDay == true ? marginRate = marginRate * 1.12 : marginRate = marginRate
      
          const rate = Number(marginRate).toFixed(0)

          return {rate: rate, eqMarkup: eqMarkup}
    }

    

    return(
        <>
            
            
            
                <div className="form-row">
                    <div className="form-group col-md-4 field">
                        <label className="calc-label">Equipment Type</label>
                        <label className="eqType-label">
                        <select className="custom-select" id="eqType" type="hidden" name="eqType" value={formData.eqType} onChange={handleChange}>
                            <option value="VAN">Van</option>
                            <option value="FLATBED">Flatbed</option>
                            <option value="REEFER">Reefer</option>
                            <option value="HOTSHOT">Hot Shot</option>
                        </select>
                      </label>
                    </div>
                    <div className="form-group col-md-4 field">
                        <label className="calc-label">Ship Date</label>
                        <input type="date" className="form-control" id="shipDate" name="shipDate" value={formData.shipDate} onChange={handleChange}/>
                    </div>
                    <div className="form-group col-md-4 field">
                        
                        <label className="form-check-label" htmlFor="sameDayCheck" >
                            <input className="form-check-input" type="checkbox" id="isSameDay" name="isSameDay" checked={isSameDay} onChange={handleCheck}/>
                            Same Day
                        </label>
                    
                    </div>
                    <GeoList onStopsArrUpdate={handleStopsArrUpdate}/>
                </div>
                <div className='vert-spacer'>
                    <button className="btn btn-primary btn-search" onClick={handleSubmit} id="submit">Get Bid</button>
                    <div className='loader-container'>
                    <Loader isLoading={isSearching} />
                    </div>
                </div>          
           
            </>     
    )
    
}

const GeoWrapper = ({id, onStopUpdate, stopIndex}) => {

    function onPlaceSelect(value) {
        console.log("onPlaceSelect", value);
        console.log("placeSelect more vals", value.properties.country)

        let toCountryString = value.properties.country_code
        let countryCode = toCountryString.toUpperCase()

        onStopUpdate({id: id, city: value.properties.city, state: value.properties.state_code, country: countryCode, zip: value.properties.postcode}, id, {lat: value.properties.lat, lon: value.properties.lon} )
      }
     
      function onSuggectionChange(value) {
        console.log(value);
      }

      function onSuggestionFilter(suggestions) {
        for (let i = 0; i < suggestions.length; i++) {
            const e = suggestions[i];
            if(e.properties.result_type === "county"){
                suggestions.splice(i, 1)
            }
            if(e.properties.address_line1.includes("City of")){
                suggestions.splice(i, 1)
            }
            if(e.properties.address_line2.includes("Town of")){
                suggestions.splice(i, 1)
            }
        }
        return suggestions
      }

    const [typeAutocomplete, setTypeAutocomplete] = useState("city")

    const isZip = (input) => {
        return !isNaN(input)
    }

    const handleUserInput = (userInput) => {
        if(userInput.length > 2){
            const fork = isZip(userInput)
            console.log("isZip?", fork)
            if(fork){
                setTypeAutocomplete("postcode")
                console.log("using postcode")
            } else {
                setTypeAutocomplete("city")
                console.log("using city")
            }
        }
    }

   const [labelText, setlabelText] = useState("")

   useEffect(() => {
    if(id === 0){
        setlabelText("Pickup")
        
    } else if(stopIndex === id) {
        setlabelText("Dropoff")
    }
    else {
        setlabelText("Stop " + id)
    }

   }, [stopIndex])

    let language = "en"
    // let position: GeoPosition = {lat: 5, lon: 6}
    let filterByCountryCode = ["us,ca"]
    let biasByCountryCode = ["us,ca"]
    let limit = 20
    let displayValue = ""
    let myGeoKey = process.env.REACT_APP_GEOAPIFY_KEY

    return(
        <>
  
            <GeoapifyContext apiKey={myGeoKey}>
                <label className="calc-label">{labelText}</label>
                <GeoapifyGeocoderAutocomplete placeholder="Enter (City, State) or Zip here"
                    type={typeAutocomplete}
                    lang={language}
                    // position={position}
                    biasByCountryCode={biasByCountryCode}
                    filterByCountryCode={filterByCountryCode}
                    limit={limit}
                    value={displayValue}
                    placeSelect={onPlaceSelect}
                    suggestionsChange={onSuggectionChange}  
                    suggestionsFilter={onSuggestionFilter}
                    onUserInput={handleUserInput} 
                    skipIcons={true}
                    
                    
                />
            </GeoapifyContext>          
        </>
    )
}

const GeoList = ({onStopsArrUpdate}) => {

    const [stopIndex, setStopIndex] = useState(1)

    const addRowHandler = () => {
        setStops([
            ...stops,
            { id: stopIndex + 1, city: "PGH", state: "Chicago", country: "Canada", zip: "15213" }
        ])
        setStopIndex(stopIndex + 1)
        console.log("stops", stops)
        console.log("is coords undefined?", stops[0].coords?.lat, stops[0].coords)
    };

    const removeRowHandler = () => {
        if(stopIndex > 1){
            setStops(stops.slice(0, stopIndex))
            setStopIndex(stopIndex - 1)
        }
        
      };

    

    const [stops, setStops] = useState([{id: 0, city: "Erie", state: "Chicago", country: "US", zip: "16506", coords: {lat: null, lon: null}}, {id: 1, city: "Erie", state: "Chicago", country: "US", zip: "16506"}]) 
    

    useEffect(() => {
        onStopsArrUpdate(stops)
    }, [stops])

    const handleStopUpdate = (stopUpdate, id, coordsUpdate) => {
        console.log("handling stop update", id, stopUpdate, coordsUpdate)

        const nextStops = stops.map(stop => {
            if(stop.id === id){
                return {
                    ...stop,
                    city: stopUpdate.city,
                    state: stopUpdate.state,
                    country: stopUpdate.country,
                    zip: stopUpdate.zip,
                    lat: coordsUpdate.lat,
                    lon: coordsUpdate.lon,
               }
            } else {
                return stop
            }
           
        })

        setStops(nextStops)

        console.log("stops array", stops)
    }

    return(
        <>
        <div className='col-sm-12'>
            <div className="row">
                <div className="col-sm-10">
                {stops.map((stop) => 
                    <GeoWrapper id={stop.id}  onStopUpdate={handleStopUpdate} stopIndex={stopIndex} />
                )}
                </div>
                <div className="card-stop col-sm-2">
                    <button className="btn btn-stop btn-stop-top" onClick={addRowHandler}>+</button>
                    <button className="btn btn-stop btn-stop-bottom" onClick={removeRowHandler}>-</button>
                </div>
            </div>
        </div>
        
        </>
    )

}